import {
  GET_JOBS,
  GET_JOB_DETAIL,
  GET_JOB_OFFER,
  GET_JOB_PAYMENT,
} from "../constants/api.constants";
import axiosIns from "../libs/axios";

const jobServices = {
  getJobs: ({ page, limit, service_id, status, username, id, priority }) =>
    axiosIns.get(GET_JOBS, {
      params: {
        page,
        limit,
        service_id,
        status,
        username,
        id,
        priority,
      },
    }),
  getJob: (id) => axiosIns.get(`${GET_JOB_DETAIL}/${id}`),
  getJobOffer: (id) => axiosIns.get(`${GET_JOB_OFFER}/${id}`),
  getPayment: (id) => axiosIns.get(`${GET_JOB_PAYMENT}/${id}`),
};

export default jobServices;
