// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";

// Data
import React, { useState } from "react";
import buttons from "layouts/job/data/button";

// Mobile
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//Images mobile
import filter from "assets/images/filer.png";

import PropTypes from "prop-types";

function Search(props) {
  const { setPagination, pagination, onSearch, toggleSearch } = props;
  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");

  const handleClickExpand = () => {
    setExpand(true);
  };

  const handleCloseExpand = () => {
    setExpand(false);
  };

  const handleClearFields = () => {
    setPagination((prevState) => ({
      ...prevState,
      id: "",
      status: "0",
      username: "",
      service_id: "",
    }));
  };

  const handleSearch = () => {
    onSearch();
    toggleSearch();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (e, value) => {
    setSelectedServiceId(value);
    setPagination((prevState) => ({
      ...prevState,
      service_id: e.target.value,
    }));
  };

  return (
    <Card>
      <MDBox pt={2} pl={2} pb={2} className="search">
        <MDBox className="inputsearch">
          <MDBox pr={2}>
            <MDInput
              placeholder="Enter ID"
              value={pagination.id}
              onChange={(e) =>
                setPagination((prevState) => ({
                  ...prevState,
                  id: e.target.value,
                }))
              }
            />
          </MDBox>
          <MDBox pr={2}>
            <MDInput
              placeholder="Enter Username"
              value={pagination.username}
              onChange={(e) =>
                setPagination((prevState) => ({
                  ...prevState,
                  username: e.target.value,
                }))
              }
            />
          </MDBox>
          <MDBox pr={2}>
            <FormControl style={{ width: "150px", height: "45px" }}>
              <Select
                value={pagination.status === undefined ? "0" : pagination.status}
                onChange={(e) =>
                  setPagination((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }))
                }
                style={{ width: "100%", height: "100%" }}
              >
                <MenuItem value="0">All status</MenuItem>
                <MenuItem value="1">Available</MenuItem>
                <MenuItem value="2">Discussion</MenuItem>
                <MenuItem value="3">Progress</MenuItem>
                <MenuItem value="4">Complete</MenuItem>
                <MenuItem value="5">Canceled</MenuItem>
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
        <MDBox display="flex">
          <MDBox pr={2}>
            <MDButton color="info" onClick={handleSearch}>
              Search
            </MDButton>
          </MDBox>
          <MDBox pr={2}>
            <MDButton variant="outlined" color="info" onClick={handleClearFields}>
              CLEAR
            </MDButton>
          </MDBox>
          <MDBox pr={2}>
            <MDButton variant="outlined" color="info" onClick={handleClickExpand}>
              EXPAND
            </MDButton>
            <Dialog
              open={expand}
              keepMounted
              onClose={handleCloseExpand}
              aria-describedby="alert-dialog-slide-description"
              maxWidth="none"
            >
              <DialogTitle>Filter</DialogTitle>
              <DialogContent>
                <IconButton
                  onClick={handleCloseExpand}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "dark",
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography mt={1} variant="h6" fontWeight="bold" color="dark">
                  Categories
                </Typography>
                <MDBox mt={2} display="flex">
                  {buttons.map((button) => (
                    <MDButton
                      key={button.value}
                      variant="outlined"
                      className={selectedServiceId === button.value ? "buttonclick" : "position"}
                      value={button.value}
                      onClick={(e) => handleButtonClick(e, button.value)}
                    >
                      {button.img === null ? (
                        ""
                      ) : (
                        <img src={button.img} width="25%" style={{ marginRight: "9px" }} />
                      )}
                      {button.label}
                    </MDButton>
                  ))}
                </MDBox>

                <MDTypography mt={2} display="flex">
                  <MDTypography>
                    <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                      Status
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="5"
                          control={<Radio />}
                          label="Canceled"
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              status: e.target.value,
                            }))
                          }
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="Complete"
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              status: e.target.value,
                            }))
                          }
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="All"
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              status: e.target.value,
                            }))
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </MDTypography>
                  <MDTypography ml={9}>
                    <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                      Priority
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Normal"
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              priority: e.target.value,
                            }))
                          }
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Urgent"
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              priority: e.target.value,
                            }))
                          }
                        />
                        <FormControlLabel value="" control={<Radio />} label="All" />
                      </RadioGroup>
                    </FormControl>
                  </MDTypography>
                </MDTypography>

                <MDTypography mt={2} display="flex">
                  <MDTypography display="block" mr={7}>
                    <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                      ID
                    </Typography>
                    <MDTypography mt={1}>
                      <Stack
                        component="form"
                        sx={{
                          width: "120%",
                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter ID..."
                          variant="outlined"
                          value={pagination.id}
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              id: e.target.value,
                            }))
                          }
                        />
                      </Stack>
                    </MDTypography>
                  </MDTypography>
                  <MDTypography display="block" mr={7}>
                    <Typography mt={2} variant="h6" fontWeight="bold" color="black">
                      Username
                    </Typography>
                    <MDTypography mt={1}>
                      <Stack
                        component="form"
                        sx={{
                          width: "120%",
                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Username..."
                          variant="outlined"
                          value={pagination.username}
                          onChange={(e) =>
                            setPagination((prevState) => ({
                              ...prevState,
                              username: e.target.value,
                            }))
                          }
                        />
                      </Stack>
                    </MDTypography>
                  </MDTypography>
                </MDTypography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleSearch();
                    handleCloseExpand();
                  }}
                >
                  Search
                </Button>
              </DialogActions>
            </Dialog>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox className="appsearch">
        <MDTypography p={2} variant="h4" color="dark">
          Jobs
        </MDTypography>
        <MDTypography variant="h3" color="dark">
          <Button variant="none" onClick={handleClickOpen}>
            <img src={filter} width="50%" />
          </Button>
          <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            style={{ width: "112%", marginLeft: "-6%" }}
          >
            <DialogTitle>Filter</DialogTitle>
            <DialogContent>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "dark",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography mt={1} variant="h6" fontWeight="bold" color="dark">
                Categories
              </Typography>
              <MDBox mt={2} className="overflow">
                {buttons.map((button) => (
                  <MDButton
                    key={button.value}
                    variant="outlined"
                    className={selectedServiceId === button.value ? "buttonclick" : "position"}
                    value={button.value}
                    onClick={(e) => handleButtonClick(e, button.value)}
                    width="100%"
                  >
                    {button.img === null ? (
                      ""
                    ) : (
                      <img src={button.img} width="25%" style={{ marginRight: "9px" }} />
                    )}
                    {button.label}
                  </MDButton>
                ))}
              </MDBox>

              <MDTypography mt={2} display="flex">
                <MDTypography>
                  <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                    Status
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="Canceled"
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="Complete"
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="All"
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </MDTypography>
                <MDTypography>
                  <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                    Priority
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Normal"
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            priority: e.target.value,
                          }))
                        }
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Urgent"
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            priority: e.target.value,
                          }))
                        }
                      />
                      <FormControlLabel value="" control={<Radio />} label="All" />
                    </RadioGroup>
                  </FormControl>
                </MDTypography>
              </MDTypography>

              <MDTypography mt={2} display="flex">
                <MDTypography display="block" mr={8}>
                  <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                    ID
                  </Typography>
                  <MDTypography mt={1}>
                    <Stack
                      component="form"
                      sx={{
                        width: "130%",
                      }}
                      spacing={2}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter ID..."
                        variant="outlined"
                        value={pagination.id}
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            id: e.target.value,
                          }))
                        }
                      />
                    </Stack>
                  </MDTypography>
                </MDTypography>
                <MDTypography display="block" mr={7}>
                  <Typography mt={2} variant="h6" fontWeight="bold" color="dark">
                    Username
                  </Typography>
                  <MDTypography mt={1}>
                    <Stack
                      component="form"
                      sx={{
                        width: "130%",
                      }}
                      spacing={2}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Username..."
                        variant="outlined"
                        value={pagination.username}
                        onChange={(e) =>
                          setPagination((prevState) => ({
                            ...prevState,
                            username: e.target.value,
                          }))
                        }
                      />
                    </Stack>
                  </MDTypography>
                </MDTypography>
              </MDTypography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleSearch();
                  handleClose();
                }}
              >
                Search
              </Button>
            </DialogActions>
          </Dialog>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

Search.propTypes = {
  setPagination: PropTypes.any,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    service_id: PropTypes.any,
    status: PropTypes.any,
    username: PropTypes.any,
    id: PropTypes.any,
    priority: PropTypes.any,
  }),
  onSearch: PropTypes.func,
  toggleSearch: PropTypes.func,
};

export default Search;
