// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import "./index.css";
import { Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/ComplexCard";

// Data
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { jobServices } from "services";
import Search from "./components/Search";

// Dashboard components

function Job() {
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [isLoad, setIsLoad] = useState(false);

  const { format } = require("date-fns");
  const handleDate = (timeStr) => {
    return new Date(timeStr);
  };

  const toggleBox = () => {
    setIsLoad(true);
    setTimeout(() => {
      setIsBoxVisible(!isBoxVisible);
      setIsLoad(false);
    }, 1500);
  };

  const toggleSearch = () => {
    setIsLoad(true);
    setTimeout(() => {
      setIsLoad(false);
    }, 1000);
  };

  useEffect(() => {
    toggleBox();
  }, []);

  const [jobs, setJobs] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    total: 0,
    service_id: undefined,
    status: undefined,
    username: undefined,
    id: undefined,
    priority: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const getJobs = async () => {
    setIsLoading(true);
    try {
      const response = await jobServices.getJobs({
        page: pagination.page,
        limit: pagination.limit,
        id: pagination.id === "" ? undefined : pagination.id,
        username: pagination.username === "" ? undefined : pagination.username,
        status: pagination.status === "" ? undefined : pagination.status,
        service_id: pagination.service_id === "" ? undefined : pagination.service_id,
        priority: pagination.priority === "" ? undefined : pagination.service_id,
      });
      setJobs(response.data.data.jobs);
      setPagination((prevState) => ({
        ...prevState,
        total: response.data.data.totalPages,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPagination((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  useEffect(() => {
    getJobs();
  }, [pagination.page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Search
              setPagination={setPagination}
              pagination={pagination}
              onSearch={getJobs}
              toggleSearch={toggleSearch}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                className="lablesearch"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h5" color="white">
                  Job List
                </MDTypography>
                <ChangeCircleIcon
                  onClick={toggleBox}
                  // disabled={isLoading}
                  color="white"
                  fontSize="large"
                />
              </MDBox>
              {isLoad ? (
                <MDBox pt={5} pl={3} pr={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" width={40} height={40} />
                          <Skeleton variant="rectangular" height={60} />
                          <Skeleton variant="rounded" height={60} />
                        </Stack>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" width={40} height={40} />
                          <Skeleton variant="rectangular" height={60} />
                          <Skeleton variant="rounded" height={60} />
                        </Stack>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" width={40} height={40} />
                          <Skeleton variant="rectangular" height={60} />
                          <Skeleton variant="rounded" height={60} />
                        </Stack>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <Stack spacing={1}>
                          <Skeleton variant="rounded" width={40} height={40} />
                          <Skeleton variant="rectangular" height={60} />
                          <Skeleton variant="rounded" height={60} />
                        </Stack>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <MDBox mb={1.5}>
                        <Stack spacing={1}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Stack>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              ) : isBoxVisible ? (
                <MDBox pt={2} pl={3} pr={3}>
                  <Paper style={{ overflowX: "auto" }}>
                    <Table style={{ overflowX: "auto" }}>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          ID
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Status
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Customer
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Fixelist
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Posted Date Time
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Scheduled Date Time
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography fontSize="15px" fontWeight="bold">
                          Complete Date Time
                        </MDTypography>
                      </TableCell>
                      <TableBody>
                        {jobs.map((job) => (
                          <TableRow key={job.id}>
                            <TableCell>
                              <MDTypography variant="caption" color="text" fontWeight="medium">
                                {job.code}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDBox ml={-1}>
                                <MDBadge
                                  badgeContent={
                                    job.status == 5
                                      ? "Canceled"
                                      : job.status == 4
                                      ? "Completed"
                                      : job.status == 3
                                      ? "Progress"
                                      : job.status == 2
                                      ? "Discussion"
                                      : "Available"
                                  }
                                  color={
                                    job.status == 5
                                      ? "warning"
                                      : job.status == 4
                                      ? "info"
                                      : "primary"
                                  }
                                  variant="gradient"
                                  size="sm"
                                />
                              </MDBox>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="caption" color="text" fontWeight="medium">
                                {job.customer.user_name}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="caption" color="text" fontWeight="medium">
                                {job.handyman_job_handyman_idTohandyman
                                  ? job.handyman_job_handyman_idTohandyman.name
                                  : ""}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography
                                component="a"
                                href={`/job/${job.id}`}
                                variant="caption"
                                color="text"
                                fontWeight="medium"
                              >
                                {format(handleDate(job.insert_time), "MM/dd/yyyy hh:mma")}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography
                                component="a"
                                variant="caption"
                                color="text"
                                fontWeight="medium"
                                href={`/job/${job.id}`}
                              >
                                {job.schedule_time === null
                                  ? ""
                                  : format(handleDate(job.schedule_time), "MM/dd/yyyy hh:mma")}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="text"
                                fontWeight="medium"
                              >
                                {job.complete_time === null
                                  ? ""
                                  : format(handleDate(job.complete_time), "MM/dd/yyyy hh:mma")}
                              </MDTypography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  <Pagination
                    className="pagination"
                    display="block"
                    count={pagination.total}
                    page={pagination.page}
                    onChange={handleChangePage}
                  />
                </MDBox>
              ) : (
                <MDBox pt={5} pl={3} pr={3}>
                  <Grid container spacing={3}>
                    {jobs.map((item, index) => (
                      <Grid item xs={12} md={6} lg={3} key={index}>
                        <MDBox mb={1.5}>
                          <Link to={`/job/${item.id}`}>
                            <ComplexCard
                              categories={item.service_id}
                              id={item.code}
                              status={
                                item.status == 5
                                  ? "Canceled"
                                  : item.status === 4
                                  ? "Completed"
                                  : item.status === 3
                                  ? "Progress"
                                  : item.status === 2
                                  ? "Discussion"
                                  : "Available"
                              }
                              customer={item.customer.user_name}
                              fixelist={
                                item.handyman_job_handyman_idTohandyman === null
                                  ? "No data"
                                  : item.handyman_job_handyman_idTohandyman.name
                              }
                              date={{
                                post: format(handleDate(item.insert_time), "MM/dd/yyyy"),
                                scheduled:
                                  item.schedule_time === null
                                    ? ""
                                    : format(handleDate(item.schedule_time), "MM/dd/yyyy hh:mma"),
                                complete:
                                  item.complete_time === null
                                    ? ""
                                    : format(handleDate(item.complete_time), "MM/dd/yyyy hh:mma"),
                              }}
                            />
                          </Link>
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>

                  <Pagination
                    className="pagination"
                    display="block"
                    count={pagination.total}
                    page={pagination.page}
                    onChange={handleChangePage}
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Job;
