/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import "./Card.css";
import union from "assets/images/Union.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Images mobile
import aricon from "assets/images/ari_con.png";
import clearningwhite from "assets/images/clearning_white.png";
import pumbingwhite from "assets/images/plumbing_white.png";
import building from "assets/images/building_color.png";
import servicewhite from "assets/images/service_white.png";

function ComplexCard({ id, categories, status, customer, fixelist, date }) {
  return (
    <Card className="card">
      <MDBox display="flex" pt={1} px={3}>
        <MDBox
          variant="gradient"
          className="category"
          borderRadius="7px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          ml={-2}
          mr={2}
        >
          <img
            src={
              categories === 1
                ? aricon
                : categories === 2
                ? union
                : categories === 3
                ? pumbingwhite
                : categories === 4
                ? building
                : categories === 5
                ? clearningwhite
                : servicewhite
            }
          />
        </MDBox>
        <MDBox textAlign="left" lineHeight={1}>
          <MDTypography variant="h6" fontWeight="bold" color="dark">
            ID: {id}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text" display="flex">
            <MDTypography variant="button" fontWeight="light" color="dark">
              Posted on {date.post}
            </MDTypography>
          </MDTypography>
          <MDTypography component="span" variant="button" fontWeight="bold" color="success">
            {customer}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDTypography component="p" variant="button" color="text" display="none" px={3} pt={0.5}>
        <MDTypography component="span" variant="button" fontWeight="bold" color="success">
          Fixelist: {fixelist}
        </MDTypography>
      </MDTypography>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography variant="button" fontWeight="light" display="flex">
            Status:
            <MDTypography
              ml={0.6}
              component="span"
              variant="button"
              fontWeight="bold"
              color={
                status === "Completed" ? "primary" : status === "Canceled" ? "error" : "secondary"
              }
            >
              {status}
            </MDTypography>
          </MDTypography>
        </MDTypography>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography variant="button" fontWeight="light" color="dark">
            Fixelist: {fixelist}
          </MDTypography>
        </MDTypography>
        <MDTypography component="p" variant="button" color="dark" display="flex">
          <MDTypography variant="button" fontWeight="light" color="dark">
            Scheduled on {date.scheduled}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexCard.defaultProps = {
  id: "",
  status: "",
  service_id: "",
  customer: "",
  fixelist: "",
  date: {
    post: "",
    scheduled: "",
    complete: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "black",
    "pink",
  ]),
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  categories: PropTypes.number.isRequired,
  customer: PropTypes.string.isRequired,
  fixelist: PropTypes.string.isRequired,
  date: PropTypes.shape({
    post: PropTypes.string,
    scheduled: PropTypes.string,
    complete: PropTypes.string,
  }),
};

export default ComplexCard;
