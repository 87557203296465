import electical from "assets/images/electical_color.png";
import blumbing from "assets/images/blumbing.png";
import building from "assets/images/building_color.png";
import cleaning from "assets/images/cleaning_color.png";
import service from "assets/images/service_color.png";
import air from "assets/images/air.png";

const buttons = [
  {
    value: "",
    label: "All Categories",
    img: null,
  },
  {
    value: "1",
    label: "Ari-con",
    img: air,
  },
  {
    value: "2",
    label: "Electical",
    img: electical,
  },
  {
    value: "3",
    label: "Plumbing",
    img: blumbing,
  },
  {
    value: "4",
    label: "Building",
    img: building,
  },
  {
    value: "5",
    label: "Cleaning",
    img: cleaning,
  },
  {
    value: "6",
    label: "Service",
    img: service,
  },
];

export default buttons;
