import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./index.css";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import TimelineItem from "examples/Timeline/TimelineItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import { Divider } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { jobServices } from "services";
import Rating from "@mui/material/Rating";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

function JobDetail() {
  const { format } = require("date-fns");
  const handleDate = (timeStr) => {
    return new Date(timeStr);
  };

  const { jobCode } = useParams();
  const [jobDetail, setJobDetail] = useState(null);
  const [jobOffer, setJobOffer] = useState(null);
  const [jobPayment, setJobPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const getJob = async () => {
    setIsLoading(true);
    try {
      const response = await jobServices.getJob(jobCode);
      setJobDetail(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobOffer = async () => {
    setIsLoading(true);
    try {
      const responses = await jobServices.getJobOffer(jobCode);
      setJobOffer(responses.data.data.offers);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobPayment = async () => {
    setIsLoading(true);
    try {
      const responses = await jobServices.getPayment(jobCode);
      setJobPayment(responses.data.data.payments);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJob();
    getJobOffer();
    getJobPayment();
  }, [jobCode]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const typographyStyle = {
    fontSize: "initial",
  };

  const boxshadow = {
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
  };

  if (!jobDetail) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    );
  }

  if (!jobOffer) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    );
  }

  if (!jobPayment) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                className="lablesearch"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h5" color="white">
                  Job Detail
                </MDTypography>
                <MDButton color="white">
                  <KeyboardBackspaceIcon />
                </MDButton>
              </MDBox>

              <MDBox pt={4} pl={3} pr={3} pb={2}>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      General Information
                    </MDTypography>
                    &nbsp;&nbsp;
                    <Grid className="full">
                      <MDBox
                        variant="gradient"
                        bgColor={
                          jobDetail.status === 4
                            ? "info"
                            : jobDetail.status === 5
                            ? "warning"
                            : "primary"
                        }
                        borderRadius="5px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pl={1}
                        pr={1}
                        width="100%"
                        height="2rem"
                        mt={-0.5}
                      >
                        <MDTypography style={{ fontSize: "15px" }} color="white">
                          {jobDetail.cancel_by === 1
                            ? "Canceled by Owner"
                            : jobDetail.cancel_by === 2
                            ? "Canceled by Handyman"
                            : jobDetail.cancel_by === 3
                            ? "Canceled by Admin"
                            : jobDetail.cancel_by === 4
                            ? "Canceled by Batch"
                            : jobDetail.status === 4
                            ? "Complete"
                            : jobDetail.status === 2
                            ? "Discussion"
                            : "Available"}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid className="full">
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            ID
                          </MDTypography>
                          <MDTypography style={typographyStyle}>{jobDetail.code}</MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Service
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {jobDetail.service.name}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Priority
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {jobDetail.is_urgent === false ? "Normal" : "Urgent"}
                          </MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Posted Date Time
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {format(handleDate(jobDetail.insert_time), "MM/dd/yyyy hh:mma")}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Scheduled Date Time
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {jobDetail.schedule_time === null
                              ? ""
                              : format(handleDate(jobDetail.schedule_time), "MM/dd/yyyy hh:mma")}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Indicative Value
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Fixelist to offer</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Customer
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {jobDetail.customer.user_name}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Fixelist
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            {jobDetail.handyman_job_handyman_idTohandyman === null
                              ? "No data"
                              : jobDetail.handyman_job_handyman_idTohandyman.name}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Worker
                          </MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Total Pay Amount
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            ${jobDetail.paid_amount === null ? "0" : jobDetail.paid_amount}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            GST (for pay amount)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            ${jobDetail.gst_amount === null ? "0" : jobDetail.gst_amount}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Penalty (Fixelist to pay)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            ${jobDetail.material_cost === null ? "0" : jobDetail.material_cost}
                          </MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Penalty (Customer to pay)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            $
                            {jobDetail.mobilization_cost === null
                              ? "0"
                              : jobDetail.mobilization_cost}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Refund to Customer
                          </MDTypography>
                          <MDTypography style={typographyStyle}>
                            ${jobDetail.labour_cost === null ? "0" : jobDetail.labour_cost}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography variant="h6" color="dark">
                            Customer&apos;s Adress
                          </MDTypography>
                          {jobDetail.customer.address.map((address) => (
                            <MDTypography key={address.id} style={typographyStyle}>
                              {address.building}&nbsp;
                              {address.street}&nbsp;
                              {address.country}
                            </MDTypography>
                          ))}
                        </Grid>
                      </MDBox>
                    </Grid>
                    <Grid className="small">
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            ID
                          </MDTypography>
                          <MDTypography style={typographyStyle}>AC#kjshdf</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Service
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Air-con</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Posted Date Time
                          </MDTypography>
                          <MDTypography style={typographyStyle}>10/10/2023 07:30AM</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Scheduled Date Time
                          </MDTypography>
                          <MDTypography style={typographyStyle}>15/10/2023 11:40PM</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Priority
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Normal</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Indicative Value
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Fixelist to offer</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Customer
                          </MDTypography>
                          <MDTypography style={typographyStyle}>thha</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Fixelist
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Meta</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Worker
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Total Pay Amount
                          </MDTypography>
                          <MDTypography style={typographyStyle}>$50.00</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            GST (for pay amount)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>$0</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Penalty (Fixelist to pay)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>$0</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Penalty (Customer to pay)
                          </MDTypography>
                          <MDTypography style={typographyStyle}>$31.60</MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h6" color="dark">
                            Refund to Customer
                          </MDTypography>
                          <MDTypography style={typographyStyle}>$20.00</MDTypography>
                        </Grid>
                      </MDBox>
                      <Divider />
                      <MDBox display="flex" pl={2}>
                        <Grid item xs={12}>
                          <MDTypography variant="h6" color="dark">
                            Customer&apos;s Adress
                          </MDTypography>
                          <MDTypography style={typographyStyle}>Quang Dong Nui thanh</MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Milestone
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TimelineItem
                      color="warning"
                      icon="check"
                      title="Job Created"
                      dateTime={format(handleDate(jobDetail.insert_time), "MM/dd/yyyy hh:mma")}
                    />
                    {jobDetail.status === 5 ? (
                      <TimelineItem
                        color="warning"
                        icon="check"
                        title="Job Cancelled"
                        dateTime={
                          jobDetail.schedule_time === null
                            ? "No data"
                            : format(handleDate(jobDetail.schedule_time), "MM/dd/yyyy hh:mma")
                        }
                        lastItem
                      />
                    ) : jobDetail.status === 4 ? (
                      <TimelineItem
                        color="warning"
                        icon="check"
                        title="Job Complete"
                        dateTime="26/10/2023 02:57 PM"
                        lastItem
                      />
                    ) : (
                      <TimelineItem color="primary" icon="check" title="Job in Progress" lastItem />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Offers
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobOffer.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Fixelist
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Labour Cost
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Material Cost
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Mobilization Cost
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              GST
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Offer Price
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Schedule Date Time
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Status
                            </MDTypography>
                          </TableCell>
                          <TableBody>
                            {jobOffer.map((offer) => (
                              <TableRow key={offer.id}>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {offer.handyman.name}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${offer.labour_cost}.00
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${offer.material_cost}.00
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${offer.mobilization_cost}.00
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${offer.gst}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    $0.00
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {offer.schedule_time === null
                                      ? ""
                                      : format(
                                          handleDate(offer.schedule_time),
                                          "MM/dd/yyyy hh:mma"
                                        )}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent={
                                        offer.status === 4
                                          ? "Canceled"
                                          : offer.status === 3
                                          ? "Accepted"
                                          : offer.status === 2
                                          ? "New"
                                          : "New"
                                      }
                                      color="warning"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Job Description
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={typographyStyle}>{jobDetail.note}</Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Job Feedbacks
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobDetail.feedback.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
                        <MDBox mr={2}>
                          <MDAvatar
                            src="https://lh3.googleusercontent.com/a/AAcHTtdE2q6ESBoGpfQG5CbAcNeliElxDSy_heqqw-fP=s96-c"
                            alt="something here"
                            shadow="md"
                          />
                        </MDBox>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="center"
                        >
                          <MDTypography variant="button" fontWeight="medium">
                            Customer Feedback
                          </MDTypography>
                          <MDTypography variant="caption" color="text">
                            Complete on 23/06/2023 11:20
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Chat
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Data
                    </MDTypography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Cost History
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDTypography variant="h6" color="black" mb={1}>
                      Stage: Posting/Offer & Acceptance
                    </MDTypography>
                    {jobDetail.offer_offer_job_idTojob.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Type
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Amount
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              GST
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Total
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Note
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Time
                            </MDTypography>
                          </TableCell>
                          <TableBody>
                            {jobDetail.offer_offer_job_idTojob.map((offer) => (
                              <TableRow key={offer.id}>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    Offer
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    $
                                    {offer.labour_cost +
                                      offer.material_cost +
                                      offer.mobilization_cost}
                                    .00
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${offer.gst}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    $
                                    {offer.gst +
                                      offer.mobilization_cost +
                                      offer.material_cost +
                                      offer.labour_cost}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="medium"
                                  ></MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent="Accepted"
                                      color="warning"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {jobDetail.progress_time === null
                                      ? ""
                                      : format(
                                          handleDate(jobDetail.progress_time),
                                          "MM/dd/yyyy hh:mma"
                                        )}
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                    <Divider />
                    <MDTypography variant="h6" color="black" mb={1}>
                      Stage: Job In Progress
                    </MDTypography>
                    {jobDetail.extra.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Type
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Amount
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              GST
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Total
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Note
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Time
                            </MDTypography>
                          </TableCell>
                          <TableBody>
                            {jobDetail.extra.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.type === 1 ? "Additional Cost" : "Discount"}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${item.amount}.0
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${item.gst}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    ${item.gst + item.amount}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.note}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent={
                                        item.status === 2
                                          ? "Accepted"
                                          : item.status === 3
                                          ? "Canceled"
                                          : "New"
                                      }
                                      color="warning"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.insert_time === null
                                      ? ""
                                      : format(handleDate(item.insert_time), "MM/dd/yyyy hh:mma")}
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel8"}
                  onChange={handleChange("panel8")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Date & Time History
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDTypography variant="h6" color="black" mb={1}>
                      Stage: Posting/Offer & Acceptance
                    </MDTypography>
                    {jobDetail.offer_offer_job_idTojob.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Fixelist
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Scheduled Date Time
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Note
                            </MDTypography>
                          </TableCell>
                          {jobDetail.offer_offer_job_idTojob.map((items, index) => (
                            <TableBody key={index}>
                              {items.history_offer.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      {item.handyman.user_name}
                                    </MDTypography>
                                  </TableCell>
                                  <TableCell>
                                    <MDBox ml={-1}>
                                      <MDBadge
                                        badgeContent={item.status === 2 ? "ACCEPTED" : "CANCELED"}
                                        color="warning"
                                        variant="gradient"
                                        size="sm"
                                      />
                                    </MDBox>
                                  </TableCell>
                                  <TableCell>
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      {item.schedule_time === null
                                        ? ""
                                        : format(
                                            handleDate(item.schedule_time),
                                            "MM/dd/yyyy hh:mma"
                                          )}
                                    </MDTypography>
                                  </TableCell>
                                  <TableCell>
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      {item.note}
                                    </MDTypography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ))}
                        </Table>
                      </Paper>
                    )}

                    <Divider />
                    <MDTypography variant="h6" color="black" mb={1}>
                      Stage: Job In Progress
                    </MDTypography>
                    {jobDetail.history_action.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              ID
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Scheduled Date Time
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Note
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Action Time
                            </MDTypography>
                          </TableCell>
                          <TableBody>
                            {jobDetail.history_action.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.handyman === null
                                      ? item.customer.user_name
                                      : item.handyman.user_name}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent={item.status === 2 ? "ACCEPTED" : "CANCELED"}
                                      color="warning"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.schedule_time === null
                                      ? ""
                                      : format(handleDate(item.schedule_time), "MM/dd/yyyy hh:mma")}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.note}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.content}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {item.action_time === null
                                      ? ""
                                      : format(handleDate(item.action_time), "MM/dd/yyyy hh:mma")}
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel9"}
                  onChange={handleChange("panel9")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Worker Detail History
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper style={{ overflowX: "auto" }}>
                      <Table style={{ overflowX: "auto" }}>
                        <TableCell>
                          <MDTypography fontSize="15px" fontWeight="bold">
                            ID
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize="15px" fontWeight="bold">
                            Assign
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize="15px" fontWeight="bold">
                            Assign Time
                          </MDTypography>
                        </TableCell>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <MDTypography variant="caption" color="text" fontWeight="medium">
                                No data
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDBox ml={-1}>
                                <MDTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="medium"
                                ></MDTypography>
                              </MDBox>
                            </TableCell>
                            <TableCell>
                              <MDTypography
                                variant="caption"
                                color="text"
                                fontWeight="medium"
                              ></MDTypography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel10"}
                  onChange={handleChange("panel10")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Reviews
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobDetail.review === null ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Customer
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Star
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Content
                            </MDTypography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                  {jobDetail.review.customer.user_name}
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <Rating
                                  name="read-only"
                                  value={jobDetail.review.star_for_handyman}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                  {jobDetail.review.content_for_customer}
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <FormControlLabel
                                  control={<Switch defaultChecked />}
                                  label="Show"
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel11"}
                  onChange={handleChange("panel11")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Payments
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobPayment.length === 0 ? (
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        No Data
                      </MDTypography>
                    ) : (
                      <Paper style={{ overflowX: "auto" }}>
                        <Table style={{ overflowX: "auto" }}>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Fixelist
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Customer
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Job Status
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Job Code
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Payment Type
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Transaction Info
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Debit
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize="15px" fontWeight="bold">
                              Credit
                            </MDTypography>
                          </TableCell>
                          <TableBody>
                            {jobPayment.map((payment) => (
                              <TableRow key={payment.id}>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.handyman.user_name}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.customer === null ? "" : payment.customer.user_name}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.status}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.job_code}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent={
                                        payment.type === 1
                                          ? "(C) Full Payment"
                                          : payment.type === 2
                                          ? "(F) Discount"
                                          : payment.type === 3
                                          ? "(C) Add Cost"
                                          : payment.type === 4
                                          ? "(C) Partial Refund"
                                          : "(F) Full Pay"
                                      }
                                      color="warning"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.charge_id}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {payment.amount}
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography
                                    variant="caption"
                                    color="text"
                                    fontWeight="medium"
                                  ></MDTypography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={boxshadow}
                  expanded={expanded === "panel12"}
                  onChange={handleChange("panel12")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <MDTypography variant="h6" color="dark">
                      Payment Detail
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Data
                    </MDTypography>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

JobDetail.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    fixelist: PropTypes.string.isRequired,
  }),
};

export default JobDetail;
